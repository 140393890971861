<template>
  <div class="secret-key">
    <div class="secret-key__row">
      <div class="secret-key__input">
        <Input
          label="Webhook secret key"
          v-model="secretKey"
          placeholder="Webhook secret key"
          is-disabled
          is-password
          postfix="password"
        />
      </div>
      <div class="secret-key__icon">
        <IconButton
          icon="clipboard"
          isRaised
          @click="handleCopy(secretKey, 'Webhook secret copied to clipboard')"
        >
        </IconButton>
      </div>
    </div>
    <p class="secret-key__info" @click="$modal.show('secretInfoModal')">
      How signature work?
    </p>
    <CustomModal name="secretInfoModal" isScrollable>
      <div class="secret-key__modal__close">
        <Icon
          name="close"
          is-clickable
          color="secondary-500"
          @click="$modal.hide('secretInfoModal')"
        />
      </div>
      <div class="secret-key__modal m-scrollable">
        <h2 class="mb-12">Step 1: Gather Required Information</h2>
        <p class="mb-8">
          Before you can verify a webhook signature, you need to gather the
          following information:
        </p>
        <ul class="mb-16">
          <li class="mb-8">
            <b>Payload:</b> The data sent in the webhook request.
          </li>
          <li class="mb-8">
            <b>Signature:</b> The signature included in the webhook request
            headers
            <i
              class="secret-key__primary"
              @click="
                handleCopy(
                  '(Yoreferee-Booking-API-WebhookSignature',
                  'Webhook request headers copied to clipboard',
                )
              "
              >(Yoreferee-Booking-API-WebhookSignature)</i
            >.
          </li>
          <li class="mb-8">
            <b>Secret Key:</b> The secret key shared between your application
            and the webhook provider.
          </li>
        </ul>
        <h2 class="mb-12">Step 2: Parse the Incoming Webhook Request</h2>
        <p class="mb-16">
          Parse the incoming webhook request to extract the payload and
          signature.
        </p>
        <h2 class="mb-12">Step 3: Generate a Signature</h2>
        <p class="mb-16">
          Generate a signature using hashing the payload with an HMAC SHA256
          algorithm using the shared secret key.
        </p>
        <h2 class="mb-12">Step 4: Compare Signatures</h2>
        <p class="mb-16">
          Compare the signature you generated in Step 3 with the signature
          included in the webhook request headers. If they match, the webhook
          request is considered valid.
        </p>
        <h2 class="mb-12">Example Implementation</h2>
        <p class="mb-16">
          Here's an example implementation of webhook signature verification in
          Node.js and TypeScript:
        </p>
        <CodeEditor :code="codeExample" class="mb-16" />
        <Button
          isSmall
          isBlock
          isOutlined
          @click="handleCopy(codeExample, 'Example code copied to clipboard')"
        >
          <Icon name="clipboard" color="primary" />
          Copy code
        </Button>
      </div>
    </CustomModal>
  </div>
</template>

<script>
import alert from "@/plugins/alert";
import CodeEditor from "@/components/common/CodeEditor";
import { codeExample } from "./signatureExample";
import hljs from "highlight.js/lib/core";
import javascript from "highlight.js/lib/languages/javascript";
hljs.registerLanguage("javascript", javascript);

export default {
  name: "SecretKeyPreview",
  components: { CodeEditor },
  props: {
    secretKey: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      codeExample,
      showSecretKey: false,
    };
  },
  methods: {
    showSecret() {
      this.showSecretKey = true;
    },
    async handleCopy(data, message) {
      navigator.clipboard
        .writeText(data)
        .then(() => {
          alert.open({
            message,
            variant: "success",
          });
          //
        })
        .catch(() => {
          alert.open({
            message: "Error occurred while copying to clipboard",
            variant: "danger",
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.secret-key {
  display: flex;
  flex-direction: column;
  gap: 8px;

  &__row {
    display: flex;

    align-items: flex-end;
    gap: 16px;
  }
  &__input {
    width: 100%;
    label {
      width: 100% !important;
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 67px;
  }

  &__modal {
    max-height: 100%;
    font-size: 14px;
    font-weight: 400;
    color: var(--color-secondary-500);
    padding: 40px;

    &__close {
      position: absolute;
      top: 16px;
      right: 16px;
    }
  }

  &__info {
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    color: var(--color-secondary-400);
    cursor: pointer;
    text-decoration: underline;
  }
  &__primary {
    cursor: pointer;
    color: $primary;
  }
}
</style>
