<template>
  <div class="event-type-select" :class="[{ error: isError }]">
    <span v-if="label" class="event-type-select__label">{{ label }}</span>
    <div class="event-type-select__options">
      <div
        v-for="option in options"
        :key="option.value"
        class="event-type-select__option"
      >
        <Checkbox
          :value="selectedOptions.includes(option)"
          @input="selectOption(option)"
          :is-disabled="isDisabled"
        >
          {{ option.name }}
        </Checkbox>
        <Icon
          @click="$modal.show(`jsonModal-${option.value}`)"
          @click.stop
          name="info"
          color="secondary-400"
          class="event-type-select__info"
        />
        <CustomModal
          :name="`jsonModal-${option.value}`"
          isScrollable
          classes="event-type-select__jsonModal"
        >
          <div class="event-type-select__jsonModal__close">
            <Icon
              name="close"
              is-clickable
              color="secondary-500"
              @click="$modal.hide(`jsonModal-${option.value}`)"
            />
          </div>
          <div class="event-type-select__jsonModal__heading">
            Response example
          </div>
          <div class="event-type-select__jsonModal">
            <CodeEditor
              :code="JSON.stringify(option.response, null, 4)"
              fixedHeight
            />
          </div>
          <div class="event-type-select__jsonModal__bottom">
            <Button
              isSmall
              isBlock
              isOutlined
              @click="handleCopy(option.response)"
            >
              <Icon name="clipboard" color="primary" />
              Copy code
            </Button>

            <Button
              isSmall
              isBlock
              isOutlined
              variant="primary"
              @click="handleDownload(option.response, option.name)"
            >
              <Icon name="download" color="primary" />
              Download
            </Button>
          </div>
        </CustomModal>
      </div>
    </div>
  </div>
</template>

<script>
import alert from "@/plugins/alert";
import CodeEditor from "@/components/common/CodeEditor";
import hljs from "highlight.js/lib/core";
import json from "highlight.js/lib/languages/json";

hljs.registerLanguage("json", json);

export default {
  name: "EventTypeSelect",
  components: { CodeEditor },
  props: {
    value: {
      type: Array,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default: () => [],
    },
    isError: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isOpened: false,
      search: "",
      customTagValue: "",
      customInputValue: "",
      moneyFormatHint: "data",
    };
  },
  watch: {},
  computed: {
    innerValue: {
      get() {
        if (!this.value) {
          return [];
        } else {
          return this.value;
        }
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
    selectedOptions() {
      return this.options.filter((option) =>
        this.innerValue.includes(option.value),
      );
    },
  },
  methods: {
    handleDownload(object, name) {
      const json = JSON.stringify(object);
      const blob = new Blob([json], { type: "application/json" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      const preparedName = name.toLowerCase().split(" ").join("_");
      link.href = url;
      link.download = `${preparedName}-response-example.json`;
      link.click();
      URL.revokeObjectURL(url);
    },
    async handleCopy(object) {
      const code = JSON.stringify(object, null, 4);
      navigator.clipboard
        .writeText(code)
        .then(() => {
          alert.open({
            message: "JSON successfully copied to clipboard",
            variant: "success",
          });
          //
        })
        .catch(() => {
          alert.open({
            message: " Error occurred while copying to clipboard",
            variant: "danger",
          });
        });
    },
    selectOption(option) {
      if (this.innerValue.includes(option.value)) {
        this.innerValue = this.innerValue.filter(
          (item) => item !== option.value,
        );
      } else {
        this.innerValue = [...this.innerValue, option.value];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.event-type-select {
  display: grid;
  gap: 10px;

  &__label {
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: var(--color-secondary-500);
  }

  &__options {
    display: grid;
    gap: 24px;
  }

  &__option {
    display: flex;
    gap: 16px;
  }

  &__tooltip {
    z-index: 4;
    margin-bottom: 8px;
  }

  &__info {
    cursor: pointer;
  }

  &.error {
    border: 1px solid red;
  }

  &__jsonModal {
    padding: 24px;

    &__heading {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 24px;
      font-weight: 700;
      padding: 16px;
    }

    &__close {
      position: absolute;
      top: 16px;
      right: 16px;
    }

    &__bottom {
      padding: 16px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      align-items: center;
      gap: 16px;

      button {
        min-width: 100% !important;
      }
    }
  }
}
</style>
