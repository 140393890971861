<template>
  <Form class="integration-form" v-slot="{ invalid, changed }">
    <div class="integration-form__fieldsGrid">
      <FormItem class="integration-form__active">
        Active
        <Switcher v-model="form.isActive" :isDisabled="isDisabled" />
      </FormItem>
      <SecretKeyPreview
        v-if="form?.params?.secret"
        :secretKey="form.params?.secret"
      />
      <FormItem rules="required" v-slot="{ isError }">
        <Select
          label="Type"
          v-model="form.type"
          placeholder="Type"
          :options="typeOptions"
          :is-error="isError"
          :is-disabled="isDisabled"
        />
      </FormItem>
      <FormItem rules="required" v-slot="{ isError }">
        <Input
          label="Name"
          v-model="form.name"
          placeholder="Name"
          :is-error="isError"
          :is-disabled="isDisabled"
        />
      </FormItem>
      <div v-if="form.type === 'webhook'" class="integration-form__fieldsGrid">
        <FormItem rules="required|url" v-slot="{ isError }">
          <Input
            label="URL"
            v-model="form.params.url"
            placeholder="URL"
            :is-error="isError"
            :is-disabled="isDisabled"
          />
        </FormItem>
        <FormItem rules="required" v-slot="{ isError }">
          <EventTypeSelect
            v-model="form.params.eventTypes"
            label="WebHook Events"
            :options="webhookEvents"
            :is-error="isError"
            :isDisabled="isDisabled"
          />
        </FormItem>
      </div>

      <p v-if="createdAt" class="integration-form__text">
        Created At: {{ createdAt }}
      </p>
    </div>
    <div class="integration-form__bottomButtons">
      <Button
        v-if="!isDisabled"
        :is-block="['xs', 'sm'].includes($mq)"
        :isDisabled="invalid || !changed || isDeleting"
        :isLoading="isSubmitting"
        @click="handleSubmit"
      >
        Save
      </Button>
      <div class="integration-form__rightButtons">
        <Button
          v-if="!isDisabled"
          :is-block="['xs', 'sm'].includes($mq)"
          isOutlined
          variant="danger"
          :isDisabled="isSubmitting"
          :isLoading="isDeleting"
          @click="handleCancel"
        >
          Cancel
        </Button>
        <Guard permission="integrations.delete" v-slot="{ isAvailable }">
          <Button
            v-if="withDelete && isAvailable"
            :is-block="['xs', 'sm'].includes($mq)"
            variant="danger"
            :isDisabled="isSubmitting"
            :isLoading="isDeleting"
            @click="handleDelete"
          >
            Delete
          </Button>
        </Guard>
      </div>
    </div>
  </Form>
</template>

<script>
import { webhookEvents } from "./mockEvents.js";
import EventTypeSelect from "@/components/integrations/EventTypeSelect/EventTypeSelect.vue";
import dialog from "@/plugins/dialog";
import Guard from "@/components/common/Guard";
import moment from "moment";
import SecretKeyPreview from "@/components/integrations/SecretKeyPreview";
import { mapState } from "vuex";
import { convertLocalToVenueTimezone } from "@/helpers/utils";

export default {
  name: "IntegrationForm",
  components: { EventTypeSelect, Guard, SecretKeyPreview },
  props: {
    value: {
      type: Object,
      required: false,
    },
    isSubmitting: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    withDelete: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        ...this.value,
      },
      isDeleting: false,
      typeOptions: [
        {
          value: "webhook",
          name: "WebHook",
        },
      ],
      webhookEvents,
      isWebhook: false,
    };
  },

  watch: {
    value(val) {
      this.form = { ...val };
    },
  },
  computed: {
    ...mapState({
      venue: (state) => state.venues.selectedVenue,
    }),
    createdAt() {
      return this.value?.createdAt.seconds
        ? convertLocalToVenueTimezone(
            moment.utc(this.value?.createdAt.seconds, "X"),
            null,
            this.venue,
          )
        : null;
    },
  },
  methods: {
    handleSubmit() {
      this.$emit("submit", {
        ...this?.form,
      });
    },
    async handleDelete() {
      const confirmed = await dialog.confirm({
        title: "Delete Integration?",
        message: "All integration data will be lost",
        okText: "Delete",
        cancelText: "Cancel",
      });
      if (confirmed) {
        this.$emit("remove");
      }
    },
    async handleCancel() {
      const confirmed = await dialog.confirm({
        title: "Are you sure? \n Changes will be lost",

        okText: "Ok",
        cancelText: "Cancel",
      });
      if (confirmed) {
        this.$router.push({
          name: "Integrations",
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.integration-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 64px;
  height: 100%;

  &__text {
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: var(--color-secondary-500);
  }

  &__active {
    display: grid;
    gap: 10px;
    width: fit-content;

    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: var(--color-secondary-500);
  }

  &__fieldsGrid {
    display: grid;
    gap: 24px;
  }

  &__bottomButtons {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__rightButtons {
    display: flex;
    align-items: center;
    gap: 16px;
  }
}
</style>
