export const codeExample = `import * as crypto from 'crypto';

// Function to verify the signature of a received payload
function verifySignature(payload: string, secret: string, signature: string): boolean {
    const hmac = crypto.createHmac('sha256', secret);
    hmac.update(payload);
    const generatedSignature = hmac.digest('hex');
    return crypto.timingSafeEqual(Buffer.from(signature), Buffer.from(generatedSignature));
}

// Parse incoming webhook request to extract payload and signature
const incomingPayload = /* Parse incoming payload */;
const incomingSignature = /* Extract signature from incoming request headers */;

// Secret key shared between your application and webhook provider
const secretKey = 'your_webhook_secret_key';

// Verify signature
const isSignatureValid = verifySignature(incomingPayload, secretKey, incomingSignature);

// Handle verification result
if (isSignatureValid) {
    console.log('Webhook signature verified successfully.');
    // Proceed with processing the webhook payload
} else {
    console.error('Webhook signature verification failed. Request may be tampered with.');
    // Handle the verification failure, e.g., log, reject the request, etc.
}`;
