import waiverSignedResponse from "./waiverSignedResponse.json";
// import newBookingResponse from "./newBookingResponse.json";

export const webhookEvents = [
  // {
  //   name: "New booking",
  //   value: "new-booking",
  //   response: newBookingResponse,
  // },
  {
    name: "Waiver signed",
    value: "waiver-signed",
    response: waiverSignedResponse,
  },
];
